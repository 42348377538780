import { getLocale } from "layout/MyAppBar";
import polyglotI18nProvider from "ra-i18n-polyglot";

const defaultLocale = getLocale();

const englishMessages = {
  ra: {
    navigation: {
      skip_nav: "Skip",
      page_rows_per_page: "Row per page:",
      page_range_info: "Page Info",
      no_results: "No Result",
      prev: "Prev",
      next: "Next",
    },
    account: {
      change_password: "Change Password",
      current_password: "Current Password",
      new_password: "New Password",
      confirm_password: "Confirm Password",
      description: "*You will be logged out after password changed.",
      change_btn: "Change",
      is_verifing:
        "Your account is under approval process. Order function will be released after verify. You may first create/edit your site information.",
    },
    appbar: {
      title: "3Masons Maintenance Platform - ",
    },
    notification: {
      http_error: "Network error. Please retry",
      order_created: "Order Created",
      notification_person_deleted: "Notification person deleted",
      notification_person_added: "Notification person added",
      arrival_qr_generated: "New 'Arrival Qr Code' generated",
      confirm_qr_generated: "New 'Confirm Order Item Qr Code' generated",
      complete_qr_generated: "New 'Comfirm Order Completion Qr Code' generated",
    },
    action: {
      save: "Save",
      edit: "Edit",
      delete: "Delete",
      refresh: "Refresh",
      cancel: "Cancel",
      clear_input_value: "Clear",
      back: "Back",
      add_order: "Add Order",
      create: "Create",
      show: "Show",
    },
    page: {
      show: "Show",
      dashboard: "Dashboard",
      order: "Order",
      order_locked: "Order (locked)",
      company_site: "Company / Sites",
      nro: "Order Item Request",
      company_service_item: "Company Service Item",
      companyStatement: "Monthly Statement",
      list: "",
      loading: "Loading",
      error: "Page Error",
      empty: "No Data",
      invite: "Please create an record",
      create: "Create",
      not_found: "Not Found",
    },
    message: {
      error: "Error Message",
      not_found: "Not Found",
    },
    dashboard: {
      title: "3Masons - Maintenance Platform",
      welcome: "Welcome",
      company: "Company",
      site: "Site",
      new_order: "New Order Request",
      check_nro: "Check NRO request",
      site_qr_code: "Site QR code",
      nav_site_list: "Go to Site list",
      latest_order: "Latest Order in Progress",
      add_site: "Add Site",
    },
    order: {
      add_new: "New Order",
      order_status: "Order Status",
      order_cat: "Order Category",
      site: "Site",
      keyword: "Keyword Search",
      order_ref: "Order Ref",
      remark: "Remark",
      remark_pics: "Remark photos",
      no_remark: "N/A",
      no_remark_pics: "N/A",
      pickup_worker: "Responsible Worker",
      job_summary: "Job Summary",
      order_item: "Order Item",
      appointment_time: "Appointment Time",
      order_porgree: "Order progress",
      arrival_time: "Arrival Time",
      emergency: "E-call",
      defeat: "Defect",
      tel: "Telephone",
      not_set: "Not Set",
      rate: "Rate the worker",
      join_team: "Add to my team",
      license: "Owned licenses",
      no_license_msg: "No license information",
      no_worker_msg: "Waiting for handyman pickup",
      order_history: "Order History",
      no_history_msg: "No order history",
      order_info: "Order Information",
      checking_report: "Checking Report",
      complete_report: "Complete Report",
      no_report_msg: "No report input",
      qty: "Qty",
      total: "Total",
      order_item_detail: "Order item detail",
      order_item_status: {
        complete: "Complete",
        incomplete: "Incomplete",
        pending: "Pending",
        waived: "Waived",
        WAIVED: "Waived",
        COMPLETE: "Complete",
      },
      status: {
        pending_dispatch: "Pending Dispatch",
        pending_pick_up: "Looking for Handyman",
        picked_up: "Picked up",
        set_off: "Set off",
        not_yet_arrived: "Not arrvied",
        arrived: "Arrived and Checking",
        finish_checking: "Finish checking and Waiting order confirm",
        prepare_material: "Preparing materials",
        confirm_completion: "Confirm Completion",
        in_progress: "In working progress",
        completed: "Completed",
        void: "Cancel",
        expired: "Expired",
      },
      category: {
        electricity: "Electrical",
        water: "Plumbing",
        stove: "Hobs & Appliances",
        door_lock: "Doors & Locks",
        ac_ceil: "Ventilating and Air Conditioning",
        other: "Others",
      },
      defectOrder: "Defect Order",
      order_form: {
        order_additional_info: "Additional Info",
        order_site: "Site",
        site_missing: "No site? Click here to add.",
        order_cat:
          "Category - (The selected Category will affect the selectable order item price list)",
        order_appointment_type: "Order appointment type",
        order_appointment_type_asap:
          "ASAP (The handyman will arrive at the branch within 4 hours of the effective time of the order)",
        order_appointment_type_book:
          "Reservation (Available time slots will be provided according to the system)",
        order_appointment_type_date: "Booking date",
        order_appointment_type_slot: "Available booking slots",
        order_emergency:
          "Urgent order? (The master will arrive at the branch within 2 hours of the effective time of the order) (Urgent order will be charged an additional expedited fee of HKD600)",
        order_remark_max_length:
          "The maximum number of characters is 30 characters",
        order_pics_max: "Please upload up to 3 pictures (png, jpg)",
      },
    },
    defect_order: {},
    company: {
      title: "Company Information",
      name: "Company name",
      br: "BR",
      nature: "Business Nature",
      conatct_number: "Contact number",
      email: "Email",
      billing_company_name: "Billing Company Name",
      billing_address: "Billing Address",
      billing_contact_person: "Billing Contact Persion",
      billing_contact_number: "Billing Contact Number",
      billing_department: "Billing Department",
      site_notification: "Site Notification Email",
    },
    site: {
      _a: "Site",
      list: "Site List",
      title: "Site Information",
      add: "Add Site",
      name: "Site Name",
      address: "Site Address",
      contact: "Contact",
      business_hour: "Business Hour",
      affiliated_company: "Affiliated Company",
      arrived_qr: "Arrival Site QR Code",
      confirm_qr: "Confirm Order Item QR Code",
      complete_qr: "Confirm Order Completion QR Code",
      generate_qr: "Generate new QR Code",
      district: "District",
    },
    serviceItem: {
      _a: "Service Item",
      title: "Serive Item List",
      name: "Service Item Name",
      type: "Type",
      type_default: "Default Item",
      charge: "Price",
      type_nro: "Additional",
      cat: "Category",
      subCat: "Sub-category",
      created_at: "Created at",
      updated_at: "Updated_at",
      log: {
        view: "Service Item Log",
        item_name: "Item Name",
        detail: "Details",
        updated_at: "Updated At",
        updated_by: "Updated By",
      },
    },
    siteRecipients: {
      site: "Site",
      type: "Type of notification",
      "order-process": "Order Workflow",
      "request-non-regular-item": "Request of new order item",
      email: "Email",
    },
    nro: {
      id: "Id",
      title: "Order Item Request",
      list: "Order Item Request list",
      order_ref: "Original Order Ref",
      status: "Approval Status",
      created_at: "Created at",
      updated_at: "Updated_at",
      edit_btn: "Approve Request",
      view_btn: "View Request",
      update_request_btn: "Approve / Reject Status",
      statuses: {
        void: "Canceled",
        approved_add_company_service_item_only:
          "Approval(Add to price list only)",
        pending_approve: "Pending Approve",
        approved: "Approved",
        rejected: "Rejected",
      },
      show: {
        approver: "Approver",
        column: {
          item_name: "Order Item Name",
          qty: "Qty",
          unit_price: "Unit Price HKD",
          total_price: "Total Price HKD",
        },
        total: "Total",
        download_pdf: "Download Quotation",
      },
    },
    companyStatement: {
      ref: "Ref",
    },
    auth: {
      username: "Username",
      password: "Password",
      sign_in: "Sign In",
      logout: "Logout",
      user_menu: "User Menu",
    },
    validation: {
      required: "Required",
    },
  },
};
const chineseMessages = {
  ra: {
    navigation: {
      skip_nav: "跳過",
      page_rows_per_page: "每頁顯示:",
      page_range_info: "每頁資料",
      no_results: "沒有資料",
      prev: "上一頁",
      next: "下一頁",
    },
    account: {
      change_password: "更改密碼",
      current_password: "現有密碼",
      new_password: "新密碼",
      confirm_password: "確認密碼",
      description: "*更改密碼後你將會被系統登出",
      change_btn: "更改",
      is_verifing:
        "你的帳戶正進行審核。建立訂單功能將會於認證後解鎖。你可以先新增或修改你的分店/維修地點資料。",
    },
    appbar: {
      title: "3Masons 維修平台 - ",
    },
    notification: {
      http_error: "Erreur réseau, veuillez réessayer",
      order_created: "訂單已建立",
      notification_person_deleted: "聯絡人已刪除",
      notification_person_added: "聯絡人已新增",
      arrival_qr_generated: '已生成新"到達店鋪"QR Code',
      confirm_qr_generated: '已生成新"確認工程項目"QR Code',
      complete_qr_generated: '已生成新"完成工程"QR Code',
    },
    action: {
      save: "儲存",
      edit: "編輯",
      delete: "刪除",
      refresh: "重新整理",
      cancel: "取消",
      clear_input_value: "清除",
      back: "返回",
      add_order: "新增訂單",
      create: "新增",
      show: "記錄",
    },
    page: {
      show: "Show",
      dashboard: "控制台",
      list: "列表",
      loading: "載入中",
      order: "訂單",
      order_locked: "訂單(已鎖上)",
      company_site: "分店/維修地點",
      company_service_item: "標準價目表",
      companyStatement: "月結單",
      nro: "加入非常用項目請求",
      error: "Page Error",
      empty: "沒有資料",
      invite: "可新增資料",
      create: "新增",
      not_found: "找不到要求頁面",
    },
    message: {
      error: "Error Message",
      not_found: "找不到要求頁面",
    },
    dashboard: {
      title: "3Masons - 工程維修平台",
      welcome: "歡迎你",
      company: "所屬公司",
      site: "所屬分店/維修地點",
      new_order: "新增訂單",
      check_nro: "查看報價同意書項目",
      site_qr_code: "分店/維修地點QR CODE",
      nav_site_list: "前往分店/維修地點列表",
      latest_order: "最近進行中訂單",
      add_site: "新增分店/維修地點",
    },
    order: {
      add_new: "新增訂單",
      order_status: "訂單狀態",
      order_cat: "訂單類別",
      site: "分店/維修地點",
      keyword: "單號/裝置編號搜尋",
      order_ref: "訂單號碼",
      remark: "客戶備註",
      no_remark: "未有備註",
      no_remark_pics: "未有備註相片",
      remark_pics: "客戶備註相片",
      pickup_worker: "接單師傅",
      job_summary: "維修明細",
      order_porgree: "工程進度",
      order_item: "維修項目",
      appointment_time: "預約時間",
      arrival_time: "到達時間",
      emergency: "急單",
      defeat: "執漏",
      tel: "電話",
      not_set: "未有資料",
      rate: "師傅評分",
      join_team: "加入我的團隊",
      license: "持有的牌照",
      no_license_msg: "沒有牌照資料",
      no_worker_msg: "未有接單師傅",
      order_history: "工程信息記錄",
      no_history_msg: "未有工程信息記錄",
      order_info: "訂單信息",
      checking_report: "檢查報告",
      complete_report: "完工報告",
      no_report_msg: "未輸入",
      qty: "數量",
      total: "總計",
      order_item_detail: "工程項目詳細",
      order_item_status: {
        complete: "己完成",
        incomplete: "未完工",
        pending: "待施工",
        waived: "免收",
        WAIVED: "免收",
        COMPLETE: "已完成",
      },
      status: {
        pending_dispatch: "等待派送系統",
        pending_pick_up: "等待師傅接單",
        picked_up: "已接單及待出發",
        set_off: "出發中",
        arrived: "檢查中",
        not_yet_arrived: "未到達",
        finish_checking: "檢查完成及等待確認工程項目",
        prepare_material: "已確認工程項目及備料中",
        confirm_completion: "確認收貨",
        in_progress: "開工中",
        completed: "完成收貨",
        void: "已取消",
        expired: "已過期",
      },
      category: {
        electricity: "電力",
        water: "水務",
        stove: "電器爐具",
        door_lock: "門、鎖",
        ac_ceil: "冷氣天花",
        other: "其他",
      },
      defectOrder: "執漏工程",
      order_form: {
        order_site: "分店/維修地點",
        site_missing: "未找到分店/維修地點? 按此新增",
        order_cat:
          "訂單類別 - (選擇的訂單類別會影響可選擇的師傅及工程項目價目表)",
        order_appointment_type: "訂單預約到達",
        order_appointment_type_asap:
          "ASAP (師傅會於訂單生效時間最遲4小時內到達分店/維修地點)",
        order_appointment_type_book: "預約 (可供預約時段會根據系統提供)",
        order_appointment_type_date: "預約日期",
        order_appointment_type_slot: "可供預約時段",
        order_emergency:
          "急單? (師傅會於訂單生效時間最遲2小時內到達分店/維修地點) (急單將會額外收取加急費HKD600)",
        order_remark_max_length: "最大限制字數為30字",
        order_pics_max: "最多請上載3張圖片 (png, jpg)",
        order_additional_info: "補充資料",
      },
    },
    company: {
      title: "公司資料",
      name: "公司名稱",
      br: "BR",
      nature: "業務性質",
      conatct_number: "聯絡電話",
      email: "Email",
      billing_company_name: "帳單公司名稱",
      billing_address: "帳單地址",
      billing_contact_person: "帳單聯絡人",
      billing_contact_number: "帳單聯絡電話",
      billing_department: "負責帳單部門",
      site_notification: "分店/維修地點通知電郵",
    },
    site: {
      _a: "分店/維修地點",
      title: "分店/維修地點資料",
      list: "分店/維修地點清單",
      add: "加入分店/維修地點",
      name: "分店/維修地點名稱",
      address: "分店/維修地點地址",
      contact: "聯絡方法",
      business_hour: "營業時間",
      affiliated_company: "所屬公司",
      arrived_qr: "到達店鋪QR Code",
      confirm_qr: "確認報價單QR Code",
      complete_qr: "確認完成訂單QR Code",
      generate_qr: "生成新QR Code",
      district: "地區",
    },
    serviceItem: {
      _a: "維修項目",
      title: "價目表",
      name: "維修項目名稱",
      type: "項目類別",
      type_default: "預設項目",
      charge: "價錢",
      type_nro: "後加項目",
      cat: "維修類別",
      subCat: "維修子類別",
      created_at: "建立日期",
      updated_at: "更新日期",
      log: {
        view: "查看價目表日誌",
        item_name: "項目名稱",
        detail: "更新內容",
        updated_at: "更新時間",
        updated_by: "由更新",
      },
    },
    siteRecipients: {
      site: "分店/維修地點",
      type: "通知類型",
      "order-process": "訂單工作流程",
      "request-non-regular-item": "請求非常用訂單項目",
      email: "電郵",
    },
    nro: {
      id: "Id",
      title: "非常用維修項目請求",
      list: "請求清單",
      order_ref: "原生訂單編號",
      status: "批核狀態",
      created_at: "建立於",
      updated_at: "更新於",
      edit_btn: "更新請求",
      view_btn: "查看請求",
      update_request_btn: "批准 / 拒絕 請求",
      statuses: {
        void: "取消",
        approved_add_company_service_item_only: "批准只加入報價單",
        pending_approve: "待批核",
        approved: "批准",
        rejected: "拒絕",
      },
      show: {
        approver: "批核者",
        column: {
          item_name: "項目名稱",
          qty: "數量",
          unit_price: "項目單價 HKD",
          total_price: "項目總價 HKD",
        },
        total: "總計",
        download_pdf: "下載報價單",
      },
    },
    companyStatement: {
      ref: "參考編號",
      date: "日期",
    },
    auth: {
      username: "用戶名稱",
      password: "密碼",
      sign_in: "登入",
      logout: "登出",
      user_menu: "用戶選單",
    },
    validation: {
      required: "須要",
    },
  },
};

const i18nProvider = polyglotI18nProvider(
  (locale) => (locale === "zh-TW" ? chineseMessages : englishMessages),
  defaultLocale // Default locale
);

export default i18nProvider;
