import { Typography, Chip, Box, Divider, Avatar } from "@material-ui/core";
import { getLocale } from "layout/MyAppBar";

const OrderPanel = ({ id, record, resource }) => {
  const locale = getLocale();
  return (
    <Box style={{ padding: 10 }}>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          justifyContent: "space-around",
        }}
      >
        <Box>
          {record?.workerInfo ? (
            <Box style={{ display: "flex", flexDirection: "row", gap: 8 }}>
              <Avatar
                alt={record?.workerInfo?.full_name}
                src={record?.workerInfo?.profilePic.data}
              />
              <Typography
                style={{ fontSize: "16px", color: "rgb(108 108 108)" }}
              >
                {" "}
                <strong>{record?.workerInfo?.full_name}</strong> <br />
                <small>員工編號: {record?.workerInfo?.profile?.wid}</small>
              </Typography>
            </Box>
          ) : null}
          {record?.company_statement_date ? (
            <Typography style={{ fontSize: "16px", color: "rgb(108 108 108)" }}>
              完工日期:{" "}
              {record?.company_statement_date
                ? record?.company_statement_date
                : "N/A"}
            </Typography>
          ) : null}
          {record?.checking_report ? (
            <Typography style={{ fontSize: "16px", color: "rgb(108 108 108)" }}>
              檢完報告:{" "}
              {record?.checking_report ? record?.checking_report : "N/A"}
            </Typography>
          ) : null}
          {record?.complete_report ? (
            <Typography style={{ fontSize: "16px", color: "rgb(108 108 108)" }}>
              完工報告:{" "}
              {record?.complete_report ? record?.complete_report : "N/A"}
            </Typography>
          ) : null}

          {/* {record?.workerInfo?.licenses?.map((license) => {
              return (
                <Typography
                  key={`license-${license.worker_id}-${license.id}`}
                  style={{ fontSize: "12px", color: "rgb(108 108 108)" }}
                >
                  {license.type}
                </Typography>
              );
            })} */}
        </Box>
        <Box style={{ display: "flex", flexDirection: "column", gap: 4 }}>
          <Typography variant="h6">維修項目</Typography>
          {record?.details.map((orderItem, index) => {
            return (
              <>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 8,
                    justifyContent: "space-between",
                  }}
                  key={`orderItem-${orderItem.id}`}
                >
                  <Box>
                    <Typography
                      style={{ fontSize: "16px", color: "rgb(108 108 108)" }}
                    >
                      {index + 1}.
                      {locale === "zh-TW"
                        ? orderItem.siteServiceItemName
                        : orderItem.siteServiceItemNameEn}
                    </Typography>
                    <Typography
                      style={{ fontSize: "14px", color: "rgb(108 108 108)" }}
                    >
                      數量: {orderItem.qty}
                    </Typography>
                    <Typography
                      style={{ fontSize: "14px", color: "rgb(108 108 108)" }}
                    >
                      收費: HKD {orderItem.price} * {orderItem.qty} = HKD
                      {orderItem.status == "WAIVED"
                        ? " 0(省免)"
                        : " " + orderItem.qty * orderItem.price}
                    </Typography>
                  </Box>
                  <Box>
                    {orderItem?.resultPics?.map((resultPic) => {
                      return (
                        <Box
                          style={{
                            flexBasis: "30%",
                            maxWidth: "30%",
                            maxHeight: "100px",
                            overflow: "hidden",
                            aspectRatio: "1/1",
                          }}
                        >
                          <a href={resultPic.staticServePath} target="_blank">
                            <img
                              src={resultPic.staticServePath}
                              style={{ width: "100%", maxWidth: 200 }}
                            />
                          </a>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Divider
                  style={{
                    backgroundColor: "#eee",
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />
              </>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default OrderPanel;
